module.exports = {
  mode: "jit",
  content: [
    "./src/**/*.{js,ts,jsx,tsx}",
    "./node_modules/@organic-return/foundation-react/src/components/**/*.{js,ts,jsx,tsx}",
    "./node_modules/@organic-return/foundation-gatsby-plugin-agility/src/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      colors: {
        inherit: "inherit",
        transparent: "transparent",
        current: "currentColor",
        "h1-text": "var(--color-h1-text)",
        "h1-text-inverted": "var(--color-h1-text-inverted)",
        "h2-text": "var(--color-h2-text)",
        "h2-text-inverted": "var(--color-h2-text-inverted)",
        "h3-text": "var(--color-h3-text)",
        "h3-text-inverted": "var(--color-h3-text-inverted)",
        accent: "var(--color-accent)",
        link: "var(--color-link)",
        "link-hover": "var(--color-link)",
        action: "var(--color-action)",
        "action-text": "var(--color-action-text)",
        "action-hover": "var(--color-action-hover)",
        "body-bg": "var(--color-body-bg)",
        "body-bg-inverted": "var(--color-body-bg-inverted)",
        "body-text": "var(--color-body-text)",
        "body-text-inverted": "var(--color-body-text-inverted)",
        "button-bg": "var(--color-button-bg)",
        "button-bg-inverted": "var(--color-button-bg-inverted)",
        "button-text": "var(--color-button-text)",
        "button-text-inverted": "var(--color-button-text-inverted)",
        divider: "var(--color-divider)",
        "divider-inverted": "var(--color-divider-inverted)",
        "composite-text": "var(--color-composite-text)",
      },
      fontFamily: {
        navigation: "var(--font-navigation)",
        body: "var(--font-body)",
        heading: "var(--font-heading)",
        "sub-heading": "var(--font-sub-heading)",
        h1: "var(--font-h1)",
        h2: "var(--font-h2)",
        h3: "var(--font-h3)",
      },
      screen: {
        sm: "var(--break-sm)",
        md: "var(--break-md)",
        lg: "var(--break-lg)",
        xl: "var(--break-xl)",
        "2xl": "var(--break-2xl)",
      },
    },
  },
  variants: {
    extend: {},
  },
}
